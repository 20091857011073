<template>
    <div v-if="isLoggedIn" class="report-button">
        <button @click="$modal.show('report-modal', { entityId, entityName })">
            Report {{ entityName }}
            <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect
                    width="30"
                    height="30"
                    fill="white"
                    fill-opacity="0.01"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8 5.49216V15.392C9.80013 15.0479 12.8553 14.5914 15.6581 15.5257C16.7731 15.8973 18.202 15.9828 19.5665 15.9255C20.7029 15.8777 21.7615 15.7325 22.5 15.5922V5.63567C21.5839 5.85021 20.4748 6.07491 19.3506 6.19964C17.9708 6.35275 16.5076 6.36295 15.3419 5.97437C13.1319 5.23771 10.9574 5.34886 9.11452 5.44306C8.72747 5.46285 8.35505 5.48189 8 5.49216ZM8 16.4107C9.75701 16.0684 12.6989 15.5934 15.3419 16.4744C16.6269 16.9027 18.198 16.9839 19.6085 16.9246C21.0269 16.865 22.3312 16.6609 23.1085 16.4881C23.3372 16.4373 23.5 16.2344 23.5 16V5.00003C23.5 4.84606 23.4291 4.70068 23.3077 4.60592C23.1863 4.51117 23.0281 4.47761 22.8787 4.51496C21.8867 4.76295 20.5672 5.05851 19.2404 5.20574C17.9005 5.35441 16.6137 5.34421 15.6581 5.02568C13.2478 4.22225 10.8006 4.34989 8.94158 4.44685C8.4076 4.47471 7.92214 4.50003 7.5 4.50003C7.22386 4.50003 7 4.72388 7 5.00003V25C7 25.2762 7.22386 25.5 7.5 25.5C7.77614 25.5 8 25.2762 8 25V16.4107Z"
                    fill="#FA4569"
                />
            </svg>
        </button>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "ReportButton",
    props: {
        entityId: {
            type: Number,
            required: true
        },
        entityName: {
            type: String,
            required: true,
            validator(value) {
                return ["memo", "comment"].includes(value);
            }
        }
    },
    computed: {
        ...mapGetters({
            isLoggedIn: "User/isLoggedIn"
        })
    }
}
</script>

<style lang="scss" scoped>
.report-button {
    button {
        color: $error-color;
        background-color: transparent;
        border: 0;
        padding: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
</style>
